import React from "react"

import Layout from "../templates/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Stats from "../components/stats"
import Services from "../components/services"
import Blog from "../components/blog"
import Cta from "../components/cta"
import Footer from "../components/footer"

const IndexPage = () => (
  <Layout isHeaderFixed={true}>
    <SEO
      title="One stop solution for your every digital problem"
      description="Get fast and reliable solution for your digital problem, We got you covered with all software from Website development to ECommerce site to Mobile app to AI enabled drones."
    />
    <Hero />
    {/* <Stats /> */}
    <Services />
    <Blog />
    <Cta />
    <Footer />
  </Layout>
)

export default IndexPage
