import React from "react"

const Subscribe = () => {
  return (
    <div className="bg-voilet-900 text-white md:pt-12 pt-8">
      <div className="max-w-screen-xl mx-auto px-8">
        <div className="text-center mx-auto w-full md:w-1/2 border-b pb-12 border-gray-700">
          <div className="text-3xl font-bold">Already convinced?</div>
          <div className="text-3xl font-bold">
            Start shooting your queries to us.{" "}
          </div>
          <div className="mt-8 bg-primary-500 text-white px-3 py-4 rounded w-32 mx-auto flex items-end justify-center cursor-pointer hover:bg-primary-600 duration-300 transition">
            <span>Ask Now</span>
            <svg className="w-6 h-6" viewBox="0 0 72 72">
              <defs />
              <circle cx="36" cy="36" r="23" fill="#FCEA2B" />
              <path
                fill="#3F3F3F"
                d="M45.331 38.564c3.963 0 7.178-2.862 7.178-6.389 0-1.765.448-3.53-.852-4.685s-4.345-1.704-6.326-1.704c-2.357 0-5.143.143-6.451 1.704-.894 1.065-.727 3.253-.727 4.685 0 3.527 3.212 6.389 7.178 6.389zM25.738 38.564c3.963 0 7.179-2.862 7.179-6.389 0-1.765.447-3.53-.852-4.685s-4.345-1.704-6.327-1.704c-2.356 0-5.142.143-6.451 1.704-.893 1.065-.727 3.253-.727 4.685 0 3.527 3.213 6.389 7.178 6.389z"
              />
              <g
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-width="2"
              >
                <circle cx="35.887" cy="36.056" r="23" />
                <path d="M45.702 44.862c-6.574 3.525-14.045 3.658-19.63 0M18.883 30.464s-.953 8.55 6.86 7.918c2.62-.212 7.817-.65 7.867-8.342.005-.698-.007-1.6-.81-2.63-1.065-1.367-3.572-1.971-9.945-1.422 0 0-3.446-.1-3.972 4.476z" />
                <path d="M18.953 29.93l-.433-3.37 3.833-.528M52.741 30.464s.953 8.55-6.86 7.918c-2.62-.212-7.817-.65-7.868-8.342-.004-.698.008-1.6.811-2.63 1.065-1.367 3.572-1.971 9.945-1.422 0 0 3.446-.1 3.972 4.476zM31.505 26.416s4.124 2.534 8.657 0M33.536 31.318s2.202-3.751 4.536 0" />
                <path d="M52.664 29.933l.433-3.371-3.833-.528M33.955 30.027s1.795-3.75 3.699 0" />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Subscribe
