import React from "react"

const Stats = () => {
  return (
    <div
      id="stats"
      className="max-w-screen-lg mx-auto items-center px-8 lg:px-0 py-8 md:py-20 relative"
    >
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 pr-4">
          <div className="text-sm text-primary-500 mb-2">OUR STATISTICS</div>
          <div className="text-4xl font-bold font-display">
            Some of our statistics, check our achievement
          </div>
        </div>
        <div className="w-full md:w-1/2 py-10 pl-0 md:pl-4">
          <div className="text-gray-700 text-lg">
            The truth is that we create the most effective products and services
            for our clients, thanks to which we generate great number every
            month.
          </div>
        </div>
      </div>
      <div className="flex flex-wrap my-4">
        <div className="w-full md:w-1/4">
          <div className="pb-4 md:pb-0">
            <div className="text-4xl text-black font-display font-bold">
              52+
            </div>
            <div className="text-gray-900">Over customers Worldwide</div>
          </div>
        </div>
        <div className="w-full md:w-1/4">
          <div className="md:mx-auto pb-4 md:pb-0">
            <div className="text-4xl text-black font-bold font-display">
              100+
            </div>
            <div className="text-gray-900">Employees</div>
          </div>
        </div>
        <div className="w-full md:w-1/4">
          <div className="md:ml-auto pb-4 md:pb-0">
            <div className="text-4xl text-black font-display font-bold">5+</div>
            <div className="text-gray-900">Offices Worldwide</div>
          </div>
        </div>
        <div className="w-full md:w-1/4">
          <div className="md:ml-auto ">
            <div className="text-4xl text-black font-display font-bold">
              99%
            </div>
            <div className="text-gray-900">Repeat Business</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stats
