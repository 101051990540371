import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Blog = () => {
  const data = useStaticQuery(
    graphql`
      query {
        firstImage: file(relativePath: { eq: "blog-1.png" }) {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        secondImage: file(relativePath: { eq: "blog-2.png" }) {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        thirdImage: file(relativePath: { eq: "blog-3.png" }) {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <div className="bg-white">
      <div className="max-w-screen-lg mx-auto items-center px-8 lg:px-0 md:pt-16 relative py-8">
        <div className="text-4xl text-black font-bold font-display mb-16">
          Our recent press
        </div>
        <div className="flex flex-wrap md:-mx-4">
          <div className="w-full md:w-1/3 md:px-4 mb-8">
            <div className="w-full bg-gray-100">
              <Img fluid={data.firstImage.childImageSharp.fluid} />
            </div>
            <div className="text-sm mt-8 mb-2 text-gray-500">TOOLS</div>
            <div className="text-black font-bold cursor-pointerso">
              The coolest marketing strategy for our clients.
            </div>
            <div className="mt-4 text-primary-500 cursor-pointer">
              READ MORE
            </div>
          </div>
          <div className="w-full md:w-1/3 md:px-4 mb-8">
            <div className="w-full bg-gray-100">
              <Img fluid={data.secondImage.childImageSharp.fluid} />
            </div>
            <div className="text-sm mt-8 mb-2 text-gray-500">TOOLS</div>
            <div className="text-black font-bold cursor-pointerso">
              The coolest marketing strategy for our clients.
            </div>
            <div className="mt-4 text-primary-500 cursor-pointer">
              READ MORE
            </div>
          </div>
          <div className="w-full md:w-1/3 md:px-4 mb-8">
            <div className="w-full bg-gray-100">
              <Img fluid={data.thirdImage.childImageSharp.fluid} />
            </div>
            <div className="text-sm mt-8 mb-2 text-gray-500">TOOLS</div>
            <div className="text-black font-bold cursor-pointerso">
              The coolest marketing strategy for our clients.
            </div>
            <div className="mt-4 text-primary-500 cursor-pointer">
              READ MORE
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Blog
