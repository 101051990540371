import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import AirBnbLogo from "../svgs/airbnb.svg"
import Fitbit from "../svgs/fitbit.svg"
import Hubspot from "../svgs/hubspot.svg"
import Slack from "../svgs/slack.svg"
import Weebly from "../svgs/weebly.svg"
import Pattern from "../svgs/patternpad.svg"
import Slider from "react-slick"

const Hero = () => {
  const data = useStaticQuery(
    graphql`
      query {
        hero: file(relativePath: { eq: "hero.webp" }) {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 4,
    centerMode: true,
    // slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <div className="relative bg-white overflow-hidden md:pt-20 pt-24">
      <div className="">
        <div className="flex flex-wrap ">
          <div className="w-full lg:w-1/2">
            <main className="max-w-xl md:mx-auto mx-8 flex py-8 lg:py-0 lg:h-92vh h-full">
              <div className="sm:text-center lg:text-left m-auto">
                <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                  Tools to enrich your <br className="xl:hidden" />
                  <span className="text-primary-600">online business</span>
                </h2>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Prepare yourself for a bright future full with enthusiasm and
                  customers. Let's do a quick audio/video/message chat in which
                  you tell us about your business needs.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded shadow hover:-translate-y-1 transform transition duration-300">
                    <a
                      href="#"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                    >
                      Schedule Meeting
                    </a>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3 hover:-translate-y-1 transform transition duration-300">
                    <a
                      href="#services"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded text-primary-600 bg-primary-100 hover:text-primary-500 hover:bg-primary-50 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                    >
                      Explore more
                    </a>
                  </div>
                </div>
              </div>
            </main>
          </div>
          <div className="w-full lg:w-1/2">
            <Pattern className="h-60 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-92vh" />

            {/* <Img
              className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-92vh"
              fluid={data.hero.childImageSharp.fluid}
            /> */}
          </div>
        </div>
      </div>
      <div className="py-16">
        <Slider {...settings} className="flex space-x-24 space-x-60">
          <div className="mx-auto w-1/2 md:w-1/3 lg:w-1/5 py-4">
            <AirBnbLogo className="w-32 mx-auto" />
          </div>
          <div className="mx-auto w-1/2 md:w-1/3 lg:w-1/5 py-4">
            <Fitbit className="w-32 mx-auto" />
          </div>
          <div className="mx-auto w-1/2 md:w-1/3 lg:w-1/5 py-4">
            <Hubspot className="w-32 mx-auto" />
          </div>
          <div className="mx-auto w-1/2 md:w-1/3 lg:w-1/5 py-4">
            <Slack className="w-32 mx-auto" />
          </div>
          <div className="mx-auto w-1/2 md:w-1/3 lg:w-1/5 py-4">
            <Weebly className="w-32 mx-auto" />
          </div>

          <div className="mx-auto w-1/2 md:w-1/3 lg:w-1/5 py-4">
            <Fitbit className="w-32 mx-auto" />
          </div>
          <div className="mx-auto w-1/2 md:w-1/3 lg:w-1/5 py-4">
            <Hubspot className="w-32 mx-auto" />
          </div>
          <div className="mx-auto w-1/2 md:w-1/3 lg:w-1/5 py-4">
            <Slack className="w-32 mx-auto" />
          </div>
          <div className="mx-auto w-1/2 md:w-1/3 lg:w-1/5 py-4">
            <Weebly className="w-32 mx-auto" />
          </div>
        </Slider>
      </div>
    </div>
  )
}

export default Hero
